import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params,Router } from '@angular/router';
import { GlobalService } from 'src/app/services/shared/global.service';
import { RoleService } from 'src/app/services/roles/role.service';

@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.css']
})
export class EditRoleComponent implements OnInit{
  form!: FormGroup;
  public roleData:any;
  public roleId: string | null = null;
  public errorMessage: string | null = null;
  public successMessage: string | null = null;
  isProcessing: boolean = false;
  submitted: boolean = false;

  constructor(
    private roleService:RoleService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private globalService: GlobalService,
  ){
    this.form = this.formBuilder.group({
      role_name: ['', Validators.required],
      'enter-bl': [false],
      'view-bl-list': [false],
      'edit-bl': [false],
      'view-bl': [false],
      'update-bl': [false],
      'delete-bl': [false],
      'assign-bl': [false],
      'print-qr-codes': [false],
      'print-bl':[false],
      'add-port-chgs': [false],
      'add-entry': [false],
      'update-entry': [false],
      'add-do-rad-pmt': [false],
      'add-duty-pmt': [false],
      'attach-duty-pmt-rcpt': [false],
      'pay-agents': [false],
      'add-cnf-pmt': [false],
      'update-vsl-eta': [false],
      'transfer-vhcl':[false],
      'receive-vhcl':[false],
      'view-vhcls-in-transit':[false],
      'view-vhcl-transfer-hist':[false],
      'view-vhcl-dtls-exp-sum': [false],
      'view-all-avail-stk': [false],
      'view-stk-enr': [false],
      'view-stk-at-cfs': [false],
      'view-sld-cars': [false],
      'view-vhcl-list':[false],
      'view-vhcl-details':[false],
      'add-vhcl-photos':[false],
      'edit-vhcl':[false],
      'add-vhcl-atts':[false],
      'view-vhcl-atts':[false],
      'rplc-vhcl-atts':[false],
      'import-vhcl-regnos':[false],
      'add-trade-in-vhcl':[false],
      'add-imp-docs':[false],
      'mng-vhcl-makes':[false],
      'mng-vhcl-models':[false],
      'mng-vhcl-grds':[false],
      'mng-vhcl-body-types':[false],
      'mng-vhcl-colours':[false],
      'mng-vhcl-spare-parts':[false],
      'mng-consignees':[false],
      'mng-agents':[false],
      'mng-cfs':[false],
      'mng-bnk-accts':[false],
      'mng-locations':[false],
      'mng-branches':[false],
      'mng-users':[false],
      'mng-roles':[false],
      'view-completed-bls':[false],
      'create-app':[false],
      'view-bl_no':[false],
      'view-entry_no':[false],
      'view-doc_ref_no':[false],
      'view-engine_no':[false],
      'view-engine_cc':[false],
      'view-mileage':[false],
      'view-reg_no':[false],
      'view-duty_paid':[false],
      'view-cnf':[false],
      'view-do_charges':[false],
      'view-rad_charges':[false],
      'view-port_charges':[false],
      'view-whse_charges':[false],
      'view-agency_fees':[false],
      'view-other_charges':[false],
      'view-total_expe':[false],
      'view-selling_rice':[false],
      'view-agency':[false],
      'view-duty_pmt_details':[false],
      'view-company':[false],
      'view-location':[false],
      'view-status':[false],
      'process-sale':[false],
      'upload-bl-att':[false],
      'update-tax-settings':[false],
      'ver-spare-parts':[false],
      'edit-entry':[false],
      'exp-add-unit':[false],
      'exp-enter-bl':[false],
      'assign-units-to-bl':[false],
      'exp-view-bl-list':[false],
      'exp-edit-bl':[false],
      'mark-bl-as-shipped':[false],
      'exp-delete-bl':[false],
      'exp-view-bl-units':[false],
      'exp-view-stk-list':[false],
      'exp-edit-unit':[false],
      'exp-upload-vhcl-photos':[false],
      'exp-view-vhcl-atts':[false],
      'exp-update-vsl-eta':[false],
      'exp-upload-bl-att':[false],
      'print-stk':[false],
      'edit-port-chgs-inv':[false],
      'access-mobile-app':[false],
      'view-sp':[false],
      'view-duty':[false],
      'view-cnf-app':[false],
      'view-ot-chg':[false],
      'view-ttl-cst':[false],
      'mob-upd-spare-parts':[false],
      'mob-view-vhcl-atts':[false],
      'del-port-chgs-inv':[false],
      'take-web-photos':[false],
      'update-sp':[false],
      'add-web-vhcl-features':[false],
      'upload-web-photos':[false],
      'transfer-vhcl-sys':[false],
      'rcv-cash':[false],
      'process-pmt':[false],
      'view-cash-trans':[false],
      'mng-cash-categories':[false],
      'del-vhcl-photos':[false],
      'exp-del-vhcl-photos':[false],
      'exp-delete-unit':[false],
      'mob-view-stk-enr':[false],
      'mob-view-stk-port-cfs':[false],
    });
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: Params)=>{
      this.roleId = param['get']('role_id')
    })

    if(this.roleId){
      this.fetchRole(this.roleId);
    }else{
      //Navigate back to users list with error
      this.globalService.setGlobalErrorMessage("Unable to select role for update!");
      this.router.navigate(['settings/roles-list']);
    }
  }

  fetchRole(roleId:string):void {
    this.roleService.getRoleData(roleId)
    .subscribe({
      next: (response) => {
        if (response && Object.keys(response).length === 0) {
          this.errorMessage="Oops! A problem was encountered while processing your request. Please refresh the page and try again!";
        }else{
          this.roleData = response;
          if(this.roleData.status==='no_data' || this.roleData.status.length===0){
            this.errorMessage=this.roleData.message;
          }else{
            if (this.roleData) {
              this.updateFormWithEditData();
            }
          }
        }
      },
      error: (error) => {
        this.errorMessage=error;
      }
    });
  }

  updateFormWithEditData() {
    // Loop through the editData.permissions array and set the corresponding form control values to true
    this.roleData.permissions.forEach((permission: string) => {
      if (this.form.controls.hasOwnProperty(permission)) {
        this.form.controls[permission].setValue(true);
      }
    });
  }

  handleSubmit():void{
    this.submitted=true;
    this.isProcessing = true;
    const selectedPermissions: string[] = Object.keys(this.form.value).filter(key => this.form.value[key]);

    // Remove the role_name from selectedPermissions array if it exists
    const roleIndex = selectedPermissions.indexOf('role_name');
    if (roleIndex !== -1) {
      selectedPermissions.splice(roleIndex, 1);
    }

    if (this.form.valid) {
      const formData = {
        role_name: this.form.get('role_name')?.value || '',
        permissions: selectedPermissions
      };

      this.roleService.updateRole(formData,this.roleId).subscribe(response => {
        if(response.status==='success'){

          this.submitted=false;
          this.isProcessing = false;
          this.globalService.setGlobalSuccessMessage(response.message);
         
          // Redirect back to roles list
          this.router.navigate(['settings/roles-list']);
        }else if(response.status==='error'){
          this.submitted=false;
          this.isProcessing = false;
          this.globalService.setGlobalErrorMessage(response.message);
        }else{
          this.submitted=false;
          this.isProcessing = false;
          this.errorMessage="Oops! A problem was encountered while processing your request. Please try again or consult your system administrator.";
        }
      },
      error => {
        this.isProcessing = false;
        this.errorMessage=error;
      });
    }else{
      this.isProcessing = false;
    }
  }

}
